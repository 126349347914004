<template>
  <div class="pa-2">
    <v-row>
      <v-col sm="12" md="3">
        <v-row>
          <v-col cols="12">
            <v-card height="200" class="pt-8">
              <v-avatar class="mx-auto d-block" size="95">
                <v-hover v-slot="{ hover }">
                  <v-img :src="`${imageRootUrl}profile/${userObj.profile}`" class="d-flex align-center justify-center">
                    <v-card style="background-color: transparent" height="100" class="d-flex align-center justify-center" width="90">
                      <v-card-text class="mx-auto px-auto">
                          <v-icon v-if="hover" color="white lighten-4" @click="$refs.uploader.click()">mdi-camera</v-icon>
                        <input ref="uploader" class="d-none" type="file" accept="image/*" @change="profileSelected">
                      </v-card-text>
                    </v-card>
                  </v-img>
                </v-hover>
              </v-avatar>
              <v-card-text class="pt-8">
                <div class="text-center">
                  <h2 class="font-weight-black">{{ (userObj && userObj.formdata) ? userObj.formdata.name : '' }}</h2>
                </div>
              </v-card-text>      
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card height="200">
              <v-card-title>Quick Links</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <p>Personal Information</p>
                <p>Update Information</p>
                <p>My Leaves</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col sm="12" md="9">
        <v-card class="pa-2" height="100%">
          <v-card-title>Personal Information</v-card-title>
          <v-divider></v-divider>
          <v-row class="pa-3 mt-4">
            <v-col v-for="(i, index) in formfields" :key="index" cols="3" class="py-1">
              <p class="font-weight-bold mb-2"> {{i.label}} </p>
              <p class="pt-0 mt-0"> {{userObj.formdata[i.model] || '--' }} </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      profileInput: null,
      userObj: {},
      formfields: []
    }
  },
  mounted () {
    this.getSingleRecordHandler(this.$route.fullPath === '/account' ? this.userDetails._id : this.$route.params.id)
  },
  methods: {
    profileSelected (e) {
      this.profileInput = e.target.files[0]
    },
    getUserRole () {
      this.$_execute('get', `user_roles/${this.userObj.role}`).then(({ data }) => {
        this.userObj.roleObj = data
        if (this.userObj.roleObj.formid) this.getFormProperties(this.userObj.roleObj.formid)
      })
    },
    getFormProperties (id) {
      this.$_execute('get', `forms/${id}`).then(({ data }) => {
        if (data && data.fields) this.formfields = data.fields
      })
    },
    getSingleRecordHandler(id) {
      this.$_execute('get', `users/${id}`).then(({ data }) => {
        this.userObj = data
        this.getUserRole()
      })
    }
  }
}
</script>

<style>

</style>